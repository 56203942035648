<ng-container *ngIf="restaurant.highlights">
  <div class="summary">{{restaurant.highlights}}</div>

  <ng-container *ngIf="isSubscribed">
    <div class="d-flex justify-content-center mb-4">
      <i class="icon-v2 delimiter-gold"></i>
    </div>

    <div class="summary">{{restaurant.downsides}}</div>
  </ng-container>


  <div class="d-flex justify-content-center mb-4">
    <button
      *ngIf="!isSubscribed"
      type="button"
      class="more-btn btn-outline-light rounded-pill mb-4"
      (click)="moreClick()"
    >
      More info
    </button>
  </div>
</ng-container>
